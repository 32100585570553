import React from 'react';
import {Link} from "react-router-dom";
import { Col, Container, Input, InputGroup, Row } from 'reactstrap'
import error from '../assets/img/404.png'

import comingsoon from '../assets/img/comingsoon.png';

const ComingSoon = () => {
    return (
        <div style={styles}>
            <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 pt-4 mb-4">
                                    <div className="mb-sm-5 pb-sm-4 pb-5">
                                        <img src={comingsoon} alt="" height="120" className="move-animation" />
                                    </div>
                                    <div className="mb-5">
                                        <h1 className="display-2 coming-soon-text">Coming Soon</h1>
                                    </div>
                                    <div>
                                        <Row className="justify-content-center mt-5">
                                            <Col lg={8}>
                                                {/* <Countdown date="2022/12/31" renderer={renderer} /> */}
                                            </Col>
                                        </Row>                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </div>
        </div>
    );
};

const styles = {
    maxWidth: 500,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: 30
};

export default ComingSoon;